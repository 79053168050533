.background {
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.mask {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(0 0 0 / 74%);
}

.textChay {
  /* font-size: 2rem; */
  text-align: center;
  font-family: YanoneKaffeesatz-Regular !important;
}

.title {
  text-align: center;
  font-family: WorkSans-ExtraBold !important;
}

.button {
  font-size: 18px !important;
  font-weight: 600 !important;
  border-radius: 21px !important;
}