.container {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  background-position: center;
}

.bold {
  font-family: 'SFProDisplay-Bold';
}

.kiaiLogo {
  margin: 5px;
  background-size: cover;
  background-position: center;
}

.divider {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  background-color: #c7c7c760 !important;
}