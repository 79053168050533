.name {
  font-family: 'WorkSans-ExtraBold' !important;
}

.info-box {
  border-bottom: solid 4px #000;
}

.circle {
  width: 32px;
  min-width: 32px;
  height: 32px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: 'WorkSans-ExtraBold';
}

.thanh-tich-title {
  margin-left: 16px !important;
  font-weight: bold !important;
}