@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: local('WorkSans-ExtraBold'), url(./assets/fonts/WorkSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  src: url(./assets/fonts/Helvetica.ttf) format('truetype');
}

@font-face {
  font-family: 'YanoneKaffeesatz-Regular';
  src: local('YanoneKaffeesatz-Regular'), url(./assets/fonts/YanoneKaffeesatz-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SFProDisplay';
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf) format('opentype');
}

body {
  margin: 0;
  color: #161616;
  font-family: 'SFProDisplay';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.formal-text {
  color: #343c41;
  text-align: justify;
  font-size: 1.15em !important;
  line-height: 1.4em !important;
  /* font-family: "Source Sans 3", sans-serif !important; */
}

@media screen and (max-width: 768px) {
  .formal-text {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
  }
}

.kpt-textfield-label {
  color: #031043 !important;
  font-size: 17px !important;
  font-weight: bold !important;
  padding-bottom: 4px;
}

strong { color: #000; }
.actions-button { font-size: 13px !important; cursor: pointer; width: fit-content; }
.ck.ck-editor__main>.ck-editor__editable { font-size: 17px; }
.MuiInputBase-input.Mui-disabled { background-color: #FAFAFA !important; }

.PrivateNotchedOutline-legendLabelled-8 { line-height: 20px; }

.paper {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.table {
  margin: 0;
  border-collapse: collapse !important;
}

.table td, .table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table {
  width: 100%;
  border-collapse: collapse !important;
}

#bc-stat {
  color: #fff;
  /* margin-bottom: 32px; */

  background: linear-gradient(
    to right,
    rgba(244, 87, 82, 1) 0%,
    rgba(244, 87, 82, 1) 20%,
    rgba(239, 30, 131, 1) 42%,
    rgba(220, 13, 159, 1) 100%
  );

  width: 100%;
  margin: 64px 0;
  margin-bottom: 0;
  padding: 32px 0;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.kep-login-facebook.small {
  height: 42px;
}

.loginGoogle {
  border-radius: 0px;
}

.grid-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

/* User Profile Picture */
.dkhs-photoContainer {
  width: 169.33px;
  height: 254px
}

.dkhs-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 2000px) {
  .dkhs-photoContainer { width: 169.33px; height: 254px; }
  .dkhs-photo {  width: 100%;  height: 100%;  object-fit: cover; }
}

@media screen and (max-width: 768px) {
  .dkhs-photoContainer { width: 119.31px; height: 179px; }
  .dkhs-photo {  width: 100%;  height: 100%;  object-fit: cover; }
}

.name {
  font-family: 'WorkSans-ExtraBold' !important;
}

ul {
  padding-inline-start: 16px;
}
