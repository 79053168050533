.textArea {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.19);
  resize: unset;
  height: 88px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  font-family: 'SFProDisplay';
}

.textArea:focus {
  border: 1px solid #3f51b5;
  outline: #3f51b5;
}

.textArea:disabled {
  color: #9E9E9E;
  background-color: #FAFAFA;
}

.dropzoneRoot {
  /* height: 40px; */
  padding: 16px 0;
  min-height: auto !important;
}

.dropzoneText {
  font-size: 17px !important;
  margin: 0 !important;
}

.dropzoneIcon {
  width: 42px !important;
}