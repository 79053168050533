.tableContainer {
  position: relative;
  padding: 0px;
  overflow-x: visible !important;
  /* width: calc(100% - 3px) !important; */
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important; */
  /* border: none !important; */
}

.tableRow {
  border-top: 1px solid rgba(224, 224, 224, 1)
}

.header {
  padding: 0 !important;
  cursor: pointer;
}

.headerContainer {
  display: flex;
}

.headerTitleContainer, .headerTitleContainerCantHover {
  flex-grow: 1;
  padding: 16px;
  /* padding-right: 0px; */
}

.bodyCell {
  border: none !important;
  padding: 16px;
}

@media screen and (max-width:768px) {
  .headerTitleContainer, .headerTitleContainerCantHover { padding: 8px; }
  .bodyCell { padding: 8px !important; }
}

.headerTitleContainer:hover, .searchIcon:hover {
  background-color: #dfdfdf;
}


.headerTitle {
  display: flex;
}

.title {
  flex-grow: 1;
}

.icon {
  width: 24px;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.sortIcon {
  height: 24px;
}

.loadingContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.resetFilterBtn {
  margin-right: 2px !important;
  width: calc(50% - 2px) !important;
}

.submitFilterBtn {
  margin-left: 2px !important;
  width: calc(50% - 2px) !important;
}