.dkhs-container {
  border-radius: 4px;
  margin-top: 32px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dkhs-idPhoto {
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid rgb(196 196 196);
}

.dkhs-insertBtn {
  top: 0;
  left: 0;
  cursor: pointer;
  text-align: center;
  position: absolute;
}

.dkhs-insertIcon {
  line-height: 20px;
  margin: auto;
  font-size: 12px;
  color: #909090;
}

.dkhs-photo {
  border-radius: 4px;
}

@media screen and (max-width: 2000px) {
  .dkhs-container { width: 100%; }
  .dkhs-photo{ object-fit: cover; width: 185px; height: 274px; }
  .dkhs-idPhoto { width: 185px; height: 274px; }
  .dkhs-insertBtn { width: 185px; height: 274px; }
  .dkhs-insertIcon { width: 185px; margin-top: 248px; }
}

@media screen and (max-width: 768px) {
  .dkhs-container { width: 100%; }
  .dkhs-photo{ object-fit: cover; width: 128px; height: 193px; }
  .dkhs-idPhoto { width: 128px; height: 193px; }
  .dkhs-insertBtn { width: 128px; height: 193px; }
  .dkhs-insertIcon { width: 128px; margin-top: 170px;  }
}
